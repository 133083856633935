import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { differenceInCalendarDays } from 'date-fns';
import { createStatusMessage, fetchStatusMessages, updateStatusMessage } from './actions';
import { ErrorMessage } from '../../utils/request/types';
import { updateProxyGroup } from '../cloudFlare/actions';
import { StatusMessage } from '../../types/statusMessages';

const statusMessagesAdapter = createEntityAdapter<StatusMessage>({
    selectId: (item) => item.id,
    sortComparer: (a, b) => differenceInCalendarDays(new Date(b.startTime), new Date(a.startTime)),
});

const slice = createSlice({
    name: 'statusMessages',
    initialState: statusMessagesAdapter.getInitialState({ isLoading: false, error: {} as ErrorMessage }),
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchStatusMessages.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(fetchStatusMessages.fulfilled, (draft, action) => {
                draft.isLoading = false;
                draft.error = {} as ErrorMessage;
                statusMessagesAdapter.addMany(draft, action.payload);
            })
            .addCase(fetchStatusMessages.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            })
            .addCase(createStatusMessage.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(createStatusMessage.fulfilled, (draft, action) => {
                draft.isLoading = false;
                draft.error = {} as ErrorMessage;
                statusMessagesAdapter.addOne(draft, action);
            })
            .addCase(createStatusMessage.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            })
            .addCase(updateStatusMessage.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(updateStatusMessage.fulfilled, (draft, action) => {
                draft.isLoading = false;
                draft.error = {} as ErrorMessage;
                statusMessagesAdapter.updateOne(draft, action.payload);
            })
            .addCase(updateStatusMessage.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            })
            .addCase(updateProxyGroup.fulfilled, (draft, action) => {
                if (!action.payload.message) return;

                // TODO Add message via adapter, when receiving an project id. Currently the project the status message belongs to is not provided in the response
                // statusMessagesAdapter.addOne(draft, action.payload.message);
            }),
});

export const statusMessagesSlice = slice.reducer;
