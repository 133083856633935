import React from 'react';
import './App.scss';
import NetworkState from './utils/NetworkState/NetworkState';
import Content from './components/content';
import Dialog from './components/shared/dialog';
import { HashRouter, Route, Routes } from 'react-router-dom';
import CenteredContainer from './utils/CenteredContainer/CenteredContainer';
import TextStringWrapper from './utils/textString/TextStringWrapper';
import textStrings from './utils/textString/textStrings';
import ContentWithFallback from './utils/ContentWithFallback/ContentWithFallback';
import ChaynsLogo from './components/shared/chayns-logo/chayns-logo';
import TobitSoftwareLogo from './components/shared/tobit-software-logo/tobit-software-logo';

const App = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const admin = queryParameters.get('admin') === 'true';

    return (
        <div className="app">
            <div className="header_bar_wrapper">
                <ContentWithFallback className="header_bar">
                    <ChaynsLogo />
                    <TobitSoftwareLogo />
                </ContentWithFallback>
            </div>
            <div className="center">
                <NetworkState onReconnect={window.location.reload} />
                <HashRouter basename="/">
                    <Routes>
                        <Route
                            path="/"
                            element={<Content showLoginPrompt={admin} />}
                            errorElement={
                                <CenteredContainer>
                                    <TextStringWrapper textString={textStrings.router.siteNotFound} />
                                </CenteredContainer>
                            }
                        />
                        <Route
                            path="/admin"
                            element={<Content showLoginPrompt />}
                            errorElement={
                                <CenteredContainer>
                                    <TextStringWrapper textString={textStrings.router.siteNotFound} />
                                </CenteredContainer>
                            }
                        />
                    </Routes>
                </HashRouter>
            </div>
            <Dialog />
        </div>
    );
};

export default App;
