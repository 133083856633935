import React, { createContext, FC, ReactElement, useState } from 'react';
import { DialogContextProviderProps } from './interfaces';
import { DialogContextProps } from './types';
import clsx from 'clsx';
import './chayns-dialog.scss';
import isMobile from '../../utils/isMobile';

export const DialogContext = createContext({} as DialogContextProps);

const DialogContextProvider: FC<DialogContextProviderProps> = ({ children }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<ReactElement>();

    const openDialog = (content: ReactElement) => {
        setDialogContent(content);
        document.querySelector('body')?.classList?.add('no-scroll');
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        document.querySelector('body')?.classList?.remove('no-scroll');
        setIsDialogOpen(false);
    };

    return (
        <DialogContext.Provider
            value={{
                isDialogOpen,
                closeDialog,
                openDialog,
            }}
        >
            {children}
            <div
                onClick={() => closeDialog()}
                className={clsx('dialog-root', {
                    'dialog-open': isDialogOpen,
                    'dialog-close': !isDialogOpen,
                    'dialog-root--desktop': !isMobile,
                })}
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={clsx('dialog', {
                        'dialog-move--in': isDialogOpen,
                        'dialog--desktop': !isMobile,
                    })}
                >
                    {dialogContent}
                </div>
            </div>
        </DialogContext.Provider>
    );
};

DialogContextProvider.displayName = 'DialogContextProvider';

export default DialogContextProvider;
