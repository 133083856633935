import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { getStatusMessages } from '../../api/status-messages/get';
import { ErrorMessage } from '../../utils/request/types';
import { postStatusMessage } from '../../api/status-messages/post';
import _ from 'lodash';
import { selectProjectById } from '../projects/selectors';
import { RootState } from '../../types/store';
import patchStatusMessage from '../../api/status-messages/patch';
import { createCustomErrorMessage } from '../../utils/request/utils';
import { StatusMessage, StatusMessagePatch, StatusMessagePost, StatusMessageRequest } from '../../types/statusMessages';

export const fetchStatusMessages = createAsyncThunk<
    Array<StatusMessage>,
    StatusMessageRequest,
    {
        rejectValue: ErrorMessage;
    }
>('statusMessages/fetchStatusMessages', async (arg, { rejectWithValue }) => {
    const { data, status, error } = await getStatusMessages();

    if (status && status >= 200 && status < 300) {
        return data as Array<StatusMessage>;
    }

    if (error)
        return rejectWithValue(
            createCustomErrorMessage({
                message: error?.message,
                fileName: 'redux-modules/status-messages/actions_fetchStatusMessages',
            })
        );

    return rejectWithValue(data as unknown as ErrorMessage);
});

export const createStatusMessage = createAsyncThunk<
    StatusMessage,
    StatusMessagePost,
    {
        rejectValue: ErrorMessage;
    }
>('statusMessages/postStatusMessage', async (arg, { getState, rejectWithValue }) => {
    const { data, status, error } = await postStatusMessage(arg);

    if (status && status >= 200 && status < 300) {
        const project = selectProjectById(getState() as RootState, arg.projectId);

        return {
            ..._.omit(arg, 'projectId'),
            id: data?.id,
            project: project.name,
        } as StatusMessage;
    }

    if (error)
        return rejectWithValue(
            createCustomErrorMessage({
                message: error?.message,
                fileName: 'redux-modules/status-messages/actions_createStatusMessage',
            })
        );

    return rejectWithValue(data as unknown as ErrorMessage);
});

export const updateStatusMessage = createAsyncThunk<
    Update<StatusMessage>,
    StatusMessagePatch,
    {
        rejectValue: ErrorMessage;
    }
>('statusMessages/patchStatusMessage', async (arg, { rejectWithValue }) => {
    const { data, status, error } = await patchStatusMessage(arg);

    if (status && status >= 200 && status < 300) {
        return {
            id: arg.id,
            changes: _.omit(arg, 'projectId'),
        } as Update<StatusMessage>;
    }

    if (error)
        return rejectWithValue(
            createCustomErrorMessage({
                message: error?.message,
                fileName: 'redux-modules/status-messages/actions_updateStatusMessage',
            })
        );

    return rejectWithValue(data as unknown as ErrorMessage);
});
