import { TextString } from 'chayns-components';
import projectConfig from '../../../package.json';
import parameterReplacer, { Parameter } from './parameterReplacer';
import { findObjectPaths } from 'find-object-paths';
import textStrings from './textStrings';

/* Gets a textString.
 This method allows to replace parameters within a textString. Wrap your parameters that should be replaced like this ##parameter##.
 Then pass an array including all parameters with the exact matching names to the parameters prop like this: [{parameter: value},{parameter1: value}] */
const getTextStringValue = (textString: string, parameters?: [Parameter, ...Parameter[]]): string => {
    const { textLibName } = projectConfig;

    const suffix = findObjectPaths(textStrings, { value: textString })?.toString().replaceAll('.', '_');

    let output = TextString.getTextString(`txt_${textLibName}_${suffix}`) || textString;
    output = parameterReplacer(output, parameters);
    return output;
};

export default getTextStringValue;
