import { ApiFunctionResult, Method } from '../../utils/request/types';
import { request } from '../../utils/request/request';
import { StatusMessage, StatusMessageRequest } from '../../types/statusMessages';

export const getStatusMessages = async (): Promise<ApiFunctionResult<Array<StatusMessage>, StatusMessageRequest>> =>
    request<Array<StatusMessage>, StatusMessageRequest>({
        method: Method.GET,
        url: `/statusmessage`,
        fileName: 'api/status-message/get_getStatusMessage',
    });
