import React, { FC, lazy, Suspense, useContext, useEffect } from 'react';
import { LoginContext } from '../contexts/admin/context';
import CenteredWaitCursor from '../utils/CenteredWaitCursor/CenteredWaitCursor';
import ContentWithFallback from '../utils/ContentWithFallback/ContentWithFallback';
import useAppSelector from '../hooks/useAppSelector';
import { selectProjectsError } from '../redux-modules/projects/selectors';
import { selectStatusMessagesError } from '../redux-modules/statusMessages/selectors';
import { selectRequestTimesError } from '../redux-modules/requestTimes/selectors';

interface ContentProps {
    showLoginPrompt?: boolean;
}

const AdminView = lazy(() => import('./views/admin-view/admin-view'));
const UserView = lazy(() => import('./views/UserView/user-view'));

const Content: FC<ContentProps> = ({ showLoginPrompt }) => {
    const { isLoggedIn, login, roles, isAdminModeOn } = useContext(LoginContext);
    const project = useAppSelector(selectProjectsError);
    const messages = useAppSelector(selectStatusMessagesError);
    const times = useAppSelector(selectRequestTimesError);

    const error = project || messages || times;

    useEffect(() => {
        if (showLoginPrompt && !isLoggedIn && !roles) {
            setTimeout(() => {
                login();
            }, 1000);
        }
    }, [isLoggedIn, showLoginPrompt]);

    return (
        <Suspense fallback={<CenteredWaitCursor />}>
            <ContentWithFallback hasError={error !== undefined} message={error?.displayMessage}>
                {showLoginPrompt && isLoggedIn && isAdminModeOn ? <AdminView /> : <UserView />}
            </ContentWithFallback>
        </Suspense>
    );
};

Content.displayName = 'Content';

export default Content;
