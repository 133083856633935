import { createSlice } from '@reduxjs/toolkit';
import { ITypedState } from '../../types/store';
import { ErrorMessage } from '../../utils/request/types';
import { fetchDomains, updateProxy, updateProxyGroup } from './actions';
import { DomainResponse } from '../../types/domain';

const initialState: ITypedState<DomainResponse> = {
    error: {} as ErrorMessage,
    data: {} as DomainResponse,
    isLoading: false,
};

const slice = createSlice({
    name: 'cloudFlare',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchDomains.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(fetchDomains.fulfilled, (draft, action) => {
                draft.isLoading = false;
                draft.data = action.payload;
            })
            .addCase(fetchDomains.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            })
            .addCase(updateProxy.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(updateProxy.fulfilled, (draft, action) => {
                draft.isLoading = false;
                draft.data = action.payload;
            })
            .addCase(updateProxy.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            })
            .addCase(updateProxyGroup.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(updateProxyGroup.fulfilled, (draft, action) => {
                draft.isLoading = false;
                draft.data = action.payload;
            })
            .addCase(updateProxyGroup.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            }),
});

export const cloudFlareSlice = slice.reducer;
