import { IEntityAdapterState, RootState } from '../../types/store';
import { EntityId } from '@reduxjs/toolkit';
import { ErrorMessage } from '../../utils/request/types';
import { Project } from '../../types/project';

export const selectProjectsMeta = (state: RootState): IEntityAdapterState<Project> => state.projects;
export const selectProjectsError = (state: RootState): ErrorMessage => state.projects?.error;
export const selectProjectsIsLoading = (state: RootState): boolean => state.projects.isLoading;
export const selectProjectById = (state: RootState, id: EntityId): Project => state.projects.entities[id as number];
export const selectProjectsIds = (state: RootState): Array<EntityId> => state.projects.ids;
export const selectAllProjects = (state: RootState): Array<Project> =>
    selectProjectsIds(state)
        .map((id) => selectProjectById(state, id))
        .sort((a, b) => a.id - b.id);
