import { IS_DEVELOPMENT, IS_PRODUCTION } from '../constants/environment';
import { createLogger } from 'redux-logger';
import { configureStore, Middleware } from '@reduxjs/toolkit';
import { projectsSlice } from './projects/slice';
import { requestTimesSlice } from './requestTimes/slice';
import { loginSlice } from './login/slice';
import { dialogsSlice } from './dialog/slice';
import { statusMessagesSlice } from './statusMessages/slice';
import { cloudFlareSlice } from './cloudFlare/slice';

export const createStore = () => {
    const rootReducer = {
        statusMessages: statusMessagesSlice,
        projects: projectsSlice,
        requestTimes: requestTimesSlice,
        login: loginSlice,
        dialogs: dialogsSlice,
        cloudFlare: cloudFlareSlice,
    };

    const reduxLogger = createLogger({
        duration: true,
        collapsed: true,
    });

    const middlewares: Middleware[] = [];
    if (IS_DEVELOPMENT) middlewares.push(reduxLogger);

    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), ...middlewares],
        devTools: !IS_PRODUCTION,
    });
};

const store = createStore();

// noinspection JSUnusedGlobalSymbols
export type AppDispatch = typeof store.dispatch;

export default store;
