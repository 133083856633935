// @ts-nocheck
import { DialogHandler } from 'chayns-api';

let dialogId = 0;

export let dialogsGlobal = {
    list: []
}

export default class DialogHelper {
    private dialogId;

    private set;

    private dialogs = [];

    constructor(setDialogs) {
        this.setDialogs = setDialogs;
    }

    openDialog = (config, callback) => {
        const currentDialogId = dialogId++;

        const eventTarget = new EventTarget();

        const resolve = (buttonType: any, data: any) => {
            // @ts-ignore //TODO fix error
            callback(buttonType, data);

            this.setDialogs(this.dialogs.filter((x) => x.dialogId !== currentDialogId));
        };

        this.setDialogs([
            ...this.dialogs,
            {
                config,
                resolve,
                dialogId: currentDialogId,
                eventTarget,
            },
        ]);

        return currentDialogId;
    };

    setCurrentDialogs = (dialogs) => {
        this.dialogs = dialogs;
    };

    closeDialog = (dialogIdFromArg) => {
        const dialog = this.dialogs.find((x) => x.dialogId === dialogIdFromArg);
        if (dialog) {
            dialog.resolve({ buttonType: -1 });
        }
    };

    dispatchEventToDialogClient = (dialogIdFromArg, data) => {
        const dialog = this.dialogs.find((x) => x.dialogId === dialogIdFromArg);
        if (dialog) {
            dialog.eventTarget.dispatchEvent(new CustomEvent('host-dialog-event', { detail: data }));
        }
    };

    addDialogClientEventListener = (dialogIdFromArg, callback) => {
        const dialog = this.dialogs.find((x) => x.dialogId === dialogIdFromArg);
        if (dialog) {
            dialog.eventTarget.addEventListener('client-dialog-event', (ev) => {
                callback(ev.detail);
            });
        }
    };
    createDialog = (config) =>
        new DialogHandler(
            config,
            this.openDialog,
            this.closeDialog,
            this.dispatchEventToDialogClient,
            this.addDialogClientEventListener
        );
}
