import { RootState } from '../../types/store';
import { EntityId } from '@reduxjs/toolkit';
import groupStatusMessages from '../../utils/groupStatusMessages';
import { ErrorMessage } from '../../utils/request/types';
import _ from 'lodash';
import { StatusMessage } from '../../types/statusMessages';

export const selectStatusMessagesIsLoading = (state: RootState): boolean => state.statusMessages.isLoading;
export const selectStatusMessagesError = (state: RootState): ErrorMessage => state.statusMessages?.error;
export const selectStatusMessagesIds = (state: RootState): Array<EntityId> => state.statusMessages.ids;
export const selectStatusMessages = (state: RootState, id: EntityId): StatusMessage =>
    state.statusMessages.entities[id as number];
export const selectAllStatusMessages = (state: RootState): Array<StatusMessage> =>
    _.sortBy(
        selectStatusMessagesIds(state).map((id) => selectStatusMessages(state, id)),
        'startTime'
    ).reverse();

export const selectCurrentStatusMessages = (state: RootState): Array<StatusMessage> =>
    selectAllStatusMessages(state).filter((message) => !message.endTime);

export const selectPastStatusMessages = (state: RootState): Array<Array<StatusMessage>> => {
    const messages = groupStatusMessages(
        selectAllStatusMessages(state).filter(({ endTime }) => typeof endTime === 'string')
    );

    const entries = Array<Array<StatusMessage>>();

    for (let key in messages) {
        entries.push(_.sortBy(messages?.[key], 'startTime', 'endTime'));
    }

    return entries;
};
