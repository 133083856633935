import { ApiFunctionResult, Method } from '../../utils/request/types';
import { request } from '../../utils/request/request';
import { RequestTimes } from '../../types/requestTime';

const getRequestTimes = async (): Promise<ApiFunctionResult<Array<RequestTimes>, undefined>> =>
    request<Array<RequestTimes>, undefined>({
        method: Method.GET,
        url: `/requestTime`,
        fileName: 'api/request-times/get_getRequestTimes',
    });

export default getRequestTimes;
