// @ts-nocheck
import React, { FC, useRef } from 'react';
import {
    AppName,
    ChaynsHost,
    ColorMode,
    Dialog,
    Environment,
    Font,
    language,
    parameters,
    RuntimeEnviroment,
    ScreenSize,
} from 'chayns-api';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setDialogs } from '../../redux-modules/dialog/slice';
import isMobile from '../../utils/isMobile';

let dialogId = 0;

export const getModuleSystem = (rawUrl: string) => {
    const urlParts = rawUrl.split('@');
    const parts = urlParts[0].split(':');

    const parsedUrl = new URL(urlParts[1]);

    return {
        url: parsedUrl.origin + parsedUrl.pathname,
        scope: parts[0],
        module: `./${parts[1]}`,
    };
};

const ApiV5Tapp: FC<{
    customData: { dialogs: Array<Dialog> };
    // @ts-ignore
}> = ({ customData, url }) => {
    const { dialogs } = customData;
    const dispatch = useAppDispatch();
    const dialogsRef = useRef(dialogs);

    dialogsRef.current = dialogs;

    return (
        <ChaynsHost
            type={'client-module'}
            system={getModuleSystem(url)}
            site={{
                id: '',
                color: 'white',
                locationId: 0,
                domain: '',
                colorMode: ColorMode.Light,
                url: '',
                title: '',
                originSiteId: '',
                urlHash: '',
                locationPersonId: '',
                layoutDisposition: {
                    barWide: false,
                    barOnTop: false,
                    contentWide: false,
                    coverDetached: false,
                    docked: false,
                    coverWide: false,
                    coverHidden: false,
                },
                font: {
                    id: Font.RobotoRegular,
                    headlineFont: Font.RobotoRegular,
                    dynamicFontSize: false,
                },
                dynamicFontSize: false,
            }}
            user={{}}
            currentPage={{
                siteId: '',
                id: 0,
            }}
            environment={{
                buildEnvironment: Environment.Production,
                runtimeEnvironment: RuntimeEnviroment.Unknown,
            }}
            pages={[]}
            isAdminModeActive={false}
            device={{
                app: {
                    name: AppName.Unknown,
                    version: 1,
                    storePackageName: '',
                },
                browser: {
                    name: 'bot',
                    version: '1',
                    majorVersion: 1,
                    isWebPSupported: true,
                },
                imei: '',
                isTouch: false,
                os: undefined,
                screenSize: isMobile ? ScreenSize.SM : ScreenSize.XL,
                accessToken: '',
            }}
            parameters={parameters}
            language={language}
            customData={customData ? { customData } : undefined}
            functions={{
                invokeCall: () => {},
                getWindowMetrics: async () => {
                    return {
                        windowHeight: window.innerHeight,
                        bottomBarHeight: 0,
                        offsetTop: 0,
                        pageHeight: window.innerHeight - 70,
                        pageSize: 0,
                        pageWidth: window.innerWidth,
                        topBarHeight: 49,
                    };
                },
                setRefreshScrollEnabled: () => {},
                addWindowMetricsListener: async () => {},
                removeWindowMetricsListener: async () => {},
                openDialog: async (config, callback) => {
                    const currentDialogId = dialogId++;

                    const eventTarget = new EventTarget();

                    const resolve = (buttonType: any, data: any) => {
                        // @ts-ignore //TODO fix error
                        callback(buttonType, data);

                        dispatch(setDialogs(dialogs.filter((x) => x.dialogId !== currentDialogId)));
                    };

                    dispatch(
                        setDialogs([
                            {
                                config,
                                resolve,
                                dialogId: currentDialogId,
                                eventTarget,
                            },
                        ])
                    );

                    return currentDialogId;
                },
                closeDialog: async (dialogId) => {
                    if (customData) {
                        // no-op?
                    } else {
                        const dialog = dialogsRef.current.find((x) => x.dialogId === dialogId);
                        if (dialog) {
                            // @ts-ignore //TODO fix error
                            dialog.resolve({ buttonType: -1 });
                        }
                    }
                },
                dispatchEventToDialogClient: async (dialogId, data) => {
                    const dialog = dialogsRef.current.find((x) => x.dialogId === dialogId);
                    if (dialog) {
                        // @ts-ignore //TODO fix error
                        dialog.eventTarget.dispatchEvent(new CustomEvent('host-dialog-event', { detail: data }));
                    }
                },
                // @ts-ignore //TODO fix error
                addDialogClientEventListener: async (dialogId, callback) => {
                    const dialog = dialogsRef.current.find((x) => x.dialogId === dialogId);
                    if (dialog) {
                        // @ts-ignore //TODO fix error
                        dialog.eventTarget.addEventListener('client-dialog-event', (ev) => {
                            callback(ev.detail);
                        });
                    }
                },
            }}
            dialog={{ dialogInput: [], isClosingRequested: false }}
        />
    );
};

ApiV5Tapp.displayName = 'ApiV5Tapp';

export default ApiV5Tapp;
