import { ApiFunctionResult, Method } from '../../../utils/request/types';
import { request } from '../../../utils/request/request';
import { DomainResponse } from '../../../types/domain';

const getDomains = async (): Promise<ApiFunctionResult<DomainResponse>> =>
    request<DomainResponse>({
        method: Method.GET,
        url: `/cloudflare/domains`,
        fileName: 'api/cloud-flare/domains_get',
    });

export default getDomains;
