import React, { FC, JSXElementConstructor } from 'react';
import CenteredWaitCursor from '../CenteredWaitCursor/CenteredWaitCursor';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { ContentCard } from '../ContentCard/ContentCard';

interface ContentWithFallbackWithProps {
    children: React.ReactNode | string;
    isLoading?: boolean;
    hasError?: boolean;
    message?: string;
    style?: Partial<CSSStyleDeclaration> | Record<string, string | number>;
    elementType?: string | JSXElementConstructor<Record<string, unknown>>;
    className?: string;
    hide?: boolean;
}

const ContentWithFallback: FC<ContentWithFallbackWithProps> = ({
    children,
    isLoading = false,
    hasError = false,
    message = '',
    style = {},
    elementType = 'div',
    className = '',
    hide = false,
}) => {
    const Component = elementType;
    return (
        <>
            {hide ? null : (
                <>
                    <ContentCard message={message} show={hasError} />
                    <ErrorBoundary>
                        {!hasError && isLoading ? <CenteredWaitCursor /> : null}
                        {!isLoading ? (
                            <Component className={className} style={{ ...style }}>
                                {children}
                            </Component>
                        ) : null}
                    </ErrorBoundary>
                </>
            )}
        </>
    );
};

ContentWithFallback.displayName = 'ContentWithFallback';

export default ContentWithFallback;
