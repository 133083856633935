/* eslint-disable max-len */
// noinspection SpellCheckingInspection

/* Stating from the object-root, going down a nested object appends a _ to the textstring.
Example : textstrings.test.string requests a textstring structured as txt_[PORJECT-PREFIX]_test_string */

const textStrings = {
    test: {
        string: 'text to have ##example## inserted.',
    },
    misc: {
        cloudFlare: 'CloudFlare',
        direct: 'Direkt',
        network: {
            offline: 'Du scheinst offline zu sein',
        },
        error: 'Fehler',
        warning: 'Warnung',
        info: 'Info',
        errorMessage:
            'Es ist ein Fehler aufgetreten. Wir sind bereits davon informiert und beheben den Fehler so schnell wie möglich.',
        more: 'Mehr',
        phone: 'Telefon',
        noEntries: 'Momentan keine Einträge',
        edit: 'Bearbeiten',
        system: 'System',
        kind: 'Art',
        times: 'Zeiten',
        title: 'Titel',
        description: 'Beschreibung',
        save: 'Speichern',
        create: 'Anlegen',
        login: 'Anmelden',
        logout: 'Abmelden',
        pending: 'Offen',
        user: 'Benutzer',
        password: 'Passwort',
        routing: 'Routen',
    },
    dialog: {
        messages: {
            loginSuccess: 'Du bist angemeldet',
            loginFailure: 'Benutzer oder Passwort sind inkorrekt',
            sessionExpired: 'Deine Sitzung ist abgelaufen',
        },
        domain: {
            route: 'Bist Du Dir sicher, das folgende Domänen umgestellt werden sollen? ##domains##',
        },
        domainGroups: {
            route: 'Sollen folgende Subdomänen ##route## über CloudFlare umgeleitet werden? ##domains##',
        },
    },
    router: {
        siteNotFound: 'Die Seite konnte nicht gefunden werden',
    },
    requestTimes: { title: 'Requestzeiten' },
    knownErrors: {
        title: 'Bekannte Störungen',
        empty: 'Keine',
        item: {
            subtitle: {
                current: 'Seit ##date## um ##time##, ##headline##',
                planed: 'Ab ##date## um ##time##, ##headline##',
            },
        },
    },
    resolvedErrors: {
        title: 'Behobene Störungen',
    },
    admin: {
        title: 'Status Meldungen',
        messages: {
            ongoing: 'Offene Meldungen',
        },
        noAccess: 'Hier gibt es gerade nichts für Dich zu tun.',
        routing: {
            domains: 'Domänen',
            groups: 'Gruppen',
        },
    },
    help: {
        title: 'Du suchst Hilfe?',
        categories: {
            forum: {
                title: 'Forum',
                description:
                    'Im moderierten Online-Forum findest Du Antworten auf Deine Fragen. Im Austausch mit der Community hilfst Du Dir selbst und anderen.',
                button: 'Zum Forum',
            },
            tutorials: {
                title: 'Tutorials',
                description: 'In kurzen Videos zeigt Tobit.Software, wie chayns funktioniert und gibt hilfreiche Tipps',
                button: 'Zu den Tutorials',
            },
            workshop: {
                title: 'Workshop',
                description:
                    'Workshops direkt bei Tobit.Software in Ahaus bieten dabei tiefe Einblicke in Anwendung und Praxis und Gelegenheit für den Austausch mit den Machern von chayns.',
                button: 'Zu den Workshops',
            },
            emergency: {
                title: 'Notfall Nummer',
                description: 'Falls Du ein akutes Problem hast, kannst Du 24/7 unser Support Team erreichen.',
                button: 'Anrufen',
            },
        },
    },
};

export default textStrings;
