import { ApiFunctionResult, Method } from '../../../utils/request/types';
import { request } from '../../../utils/request/request';
import { ProxyPatch } from '../../../types/proxy';

const patchProxy = async (body: ProxyPatch): Promise<ApiFunctionResult<undefined, ProxyPatch>> =>
    request<undefined, ProxyPatch>({
        method: Method.PATCH,
        url: `/cloudflare/proxy`,
        body,
        fileName: 'api/cloud-flare/proxy_patchProxy',
    });

export default patchProxy;
