import { createSlice } from '@reduxjs/toolkit';
import fetchRequestTimes from './actions';
import { ErrorMessage } from '../../utils/request/types';
import { ITypedState } from '../../types/store';
import { MappedProjectRequestTimes } from '../../types/requestTime';

const initialState: ITypedState<Array<MappedProjectRequestTimes>> = {
    data: {} as Array<MappedProjectRequestTimes>,
    error: {} as ErrorMessage,
    isLoading: false,
};

const slice = createSlice({
    name: 'requestTimes',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchRequestTimes.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(fetchRequestTimes.fulfilled, (draft, action) => {
                draft.isLoading = false;

                const array = Array<MappedProjectRequestTimes>();

                action.payload.forEach((snapShot) => {
                    snapShot.values.forEach((projectValue) => {
                        array.push({
                            projectId: projectValue.projectId,
                            requestTime: projectValue.requestTime,
                            captureTime: snapShot.captureTime,
                        });
                    });
                });

                draft.data = array;
            })
            .addCase(fetchRequestTimes.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            }),
});

export const requestTimesSlice = slice.reducer;
