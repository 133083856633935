import React, { FunctionComponent, JSXElementConstructor } from 'react';
import clsx from 'clsx';
import { SmallWaitCursor } from 'chayns-components';
import './centered-wait-cursor.scss';

export interface CenteredWaitCursorProps {
    className?: string;
    style?: Partial<CSSStyleDeclaration> | Record<string, string | number>;
    elementType?: string | JSXElementConstructor<Record<string, unknown>>;
    delay?: number;
    showBackground?: boolean;
    inline?: boolean;
}

/**
 * chayns-components SmallWaitCursor that centers itself, adds a margin and is only displayed after a delay
 * @param className
 * @param style
 * @param children
 * @param elementType - react element type of the container around the wait cursor, default: 'div'
 * @param delay - delay until the wait cursor is displayed
 * @param showBackground
 * @param inline
 * @param showBackground
 * @param inline
 * @param props
 * @constructor
 */
const CenteredWaitCursor: FunctionComponent<CenteredWaitCursorProps> = ({
    className = '',
    style = {},
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    children = null,
    elementType = 'div',
    delay = 300,
    showBackground = true,
    inline = false,
    ...props
}) => {
    const Component = elementType;
    return (
        <Component
            {...props}
            className={clsx(
                'chayns__utils__container',
                'chayns__utils__container--centered',
                'chayns__utils__container--centered-wait-cursor',
                className
            )}
            style={{
                animation: `${delay}ms fade-in`,
                ...style,
            }}
        >
            {children ?? <SmallWaitCursor showBackground={showBackground} inline={inline} show {...props} />}
        </Component>
    );
};

export default CenteredWaitCursor;
