import { ITypedState, RootState } from '../../types/store';
import { Domain, DomainResponse, Subdomain } from '../../types/domain';

export const selectDomainsMeta = (state: RootState): ITypedState<DomainResponse> => state.cloudFlare;
export const selectDomains = (state: RootState): Array<Domain> => state.cloudFlare.data.domains;
export const selectSubDomainByName = (state: RootState, name: string): Subdomain => {
    let subdomain;
    selectDomains(state).find((domain) => {
        subdomain = domain.subdomains.find((subdomain) => subdomain.domain.toLowerCase() === name.toLowerCase());
        return subdomain as Subdomain;
    });
    return subdomain as unknown as Subdomain;
};
export const selectDomainsIsLoading = (state: RootState): boolean => state.cloudFlare.isLoading;
