import { ApiFunctionResult, Method } from '../../utils/request/types';
import { request } from '../../utils/request/request';
import { Project } from '../../types/project';

const getProjects = async (): Promise<ApiFunctionResult<Array<Project>>> =>
    request<Array<Project>>({
        method: Method.GET,
        url: `/projects`,
        fileName: 'api/projects/get_getProjects',
    });

export default getProjects;
