import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchProjects from './actions';

import { ErrorMessage } from '../../utils/request/types';
import { Project } from '../../types/project';

const projectsEntityAdapter = createEntityAdapter<Project>({
    selectId: (item) => item.id,
});

const slice = createSlice({
    name: 'projects',
    initialState: projectsEntityAdapter.getInitialState({
        isLoading: false,
        error: {} as ErrorMessage,
    }),
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchProjects.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(fetchProjects.fulfilled, (draft, action) => {
                draft.isLoading = false;
                projectsEntityAdapter.addMany(draft, action.payload);
            })
            .addCase(fetchProjects.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            }),
});

export const projectsSlice = slice.reducer;
