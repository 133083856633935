export type DomainResponse = {
    domains: Array<Domain>;
    groups: Array<Group>;
};

export type Domain = {
    domain: string;
    subdomains: Array<Subdomain>;
};

export enum ProxyStatus {
    ENABLED = 'Enabled',
    DISABLED = 'Disabled',
}

export type Subdomain = {
    domain: string;
    proxyStatus: ProxyStatus;
    records: Array<Record>;
};

export type Record = {
    content: string;
    // noinspection SpellCheckingInspection
    proxied?: true;
    type: string;
};

export type Group = {
    id: number;
    domains: Array<Domain['domain']>;
    name: string;
};
