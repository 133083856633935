import { ChaynsLogger } from 'chayns-logger';
import { IS_PRODUCTION } from '../constants/environment';
import project from '../../package.json';

const { applicationUid } = project;

export const logger = new ChaynsLogger({
    applicationUid,
    overrideOnError: true,
    overrideConsoleError: true,
    throttleTime: 1000,
    version: process.env.REACT_APP_BUILD_VERSION,
    useDevServer: !IS_PRODUCTION,
});
