import React, { FC } from 'react';
import './dataRow.scss';
import clsx from 'clsx';
import DataRowStyle from './enums/dataRowStyle';
import { DataRowProps } from './interfaces/dataRow';

const DataRow: FC<DataRowProps> = ({ left, right, type, hide = true, children }) => (
    <>
        {!right && hide ? null : (
            <div className={clsx('data-row', { '--slim': type === DataRowStyle.SLIM })}>
                <>
                    <div>{left}</div>
                    <span>
                        <div className="centered">{right}</div>
                    </span>
                </>
            </div>
        )}
        {!children ? null : (
            <div
                style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                className={clsx('data-row', { '--slim': type === DataRowStyle.SLIM })}
            >
                {children}
            </div>
        )}
    </>
);

DataRow.displayName = 'DataRow';

export default DataRow;
