import { ITypedState, RootState } from '../../types/store';
import { ErrorMessage } from '../../utils/request/types';
import _ from 'lodash';
import { Login } from '../../types/login';

export const selectLoginMeta = (state: RootState): ITypedState<Login> => state.login;
export const selectLoginRoles = (state: RootState): Array<number> => state.login.data?.roles;
export const selectLoginExpiration = (state: RootState): string => state.login.data?.expires;
export const selectLoginToken = (state: RootState): string => state.login.data?.token;
export const selectIsLoggedIn = (state: RootState): boolean => _.isString(selectLoginToken(state));
export const selectLoginError = (state: RootState): ErrorMessage => state.login.error;
export const selectLoginIsLoading = (state: RootState): boolean => state.login.isLoading;
