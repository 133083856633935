/* eslint-disable react/sort-comp */
import React, { ErrorInfo, FunctionComponent, JSXElementConstructor, ReactNode } from 'react';
import './error-boundary.scss';
import TextStringWrapper from '../textString/TextStringWrapper';
import textStrings from '../textString/textStrings';
import { logger } from '../logger';
import getTextStringValue from '../textString/getTextStringValue';

export interface ErrorBoundaryProps {
    children: ReactNode;
    fallback?: JSXElementConstructor<{ error: Error; clearError: () => void }>;
}

export type ErrorBoundaryState = { error?: Error | null; hasError: boolean };

/**
 * An ErrorBoundary.
 * Wrap your application in this. If an error occurs during render that causes react to fail rendering, this component
 * will catch the error, log it and display a fallback instead. Its logs will be able to show you, in which component
 * the error occurred. The default fallback is a warning content card with a generic error message, error details and
 * reload button.
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    static wrap(
        WrappedComponent: JSXElementConstructor<unknown>,
        fallback?: JSXElementConstructor<{
            error: Error;
            clearError: () => void;
        }>
    ): FunctionComponent<Record<any, string>> {
        return (props: Record<string, unknown>) => (
            <ErrorBoundary fallback={fallback}>
                <WrappedComponent {...props} />
            </ErrorBoundary>
        );
    }

    /* #__PURE__ */
    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        console.error(error);
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            error,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        logger.error(
            {
                message: '[ErrorBoundary] Unexpected react error',
                data: { errorInfo },
            },
            error
        );
    }

    render(): ReactNode {
        const { state, props } = this;
        const { children, fallback } = props;
        // noinspection UnnecessaryLocalVariableJS
        const FallbackComponent = fallback;
        if (state.hasError) {
            return FallbackComponent ? (
                <FallbackComponent
                    error={state.error as Error}
                    clearError={() => {
                        this.setState({ hasError: false });
                    }}
                />
            ) : (
                <div className="ErrorBoundary">
                    <TextStringWrapper textString={textStrings.misc.errorMessage}>
                        <div>
                            {process.env.NODE_ENV === 'development' && (
                                <p>{`${getTextStringValue(textStrings.misc.error)}: ${(
                                    state.error as Error
                                ).toString()}`}</p>
                            )}
                        </div>
                    </TextStringWrapper>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
