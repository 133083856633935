import { TextString } from 'chayns-components';
import projectConfig from '../../../package.json';

const textStringInit = async (): Promise<void> => {
    const { textLibName } = projectConfig;

    await TextString.loadLibrary(textLibName, '', 'de');
};

export default textStringInit;
