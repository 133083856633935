import { ApiFunctionResult, Method } from '../../utils/request/types';
import { request } from '../../utils/request/request';
import _ from 'lodash';
import { StatusMessage, StatusMessagePatch } from '../../types/statusMessages';

const patchStatusMessage = async (
    body: StatusMessagePatch
): Promise<ApiFunctionResult<StatusMessage, StatusMessagePatch>> => {
    const { id } = body;

    return request<StatusMessage, Omit<StatusMessagePatch, 'id'>>({
        method: Method.PATCH,
        url: `/statusMessage/${id}`,
        body: _.omit(body, 'id'),
        fileName: 'api/status-message/patch_patchStatusMessage',
    });
};

export default patchStatusMessage;
