import { ErrorMessage } from './types';
import getTextStringValue from '../textString/getTextStringValue';
import textStrings from '../textString/textStrings';

export const createCustomErrorMessage = ({
    errorCode = 'unknown',
    message,
    displayMessage,
    fileName,
}: {
    displayMessage?: string;
    errorCode?: string | number;
    message?: string;
    fileName: string;
}): ErrorMessage => ({
    message: message || `A error occurred in ${fileName}`,
    errorCode: fileName + '/' + errorCode.toString(),
    displayMessage: displayMessage || getTextStringValue(textStrings.misc.error),
});

export const containsHttpOrHttpsPrefix = (url: string) => /^(https:\/\/)|^(http:\/\/)/.test(url);

export const getUtcOffsetAsString = (): string => {
    const hour = (new Date().getTimezoneOffset() * -1) / 60;

    const offset = hour.toString().length > 1 ? `${hour.toString()}:00` : `0${hour.toString()}:00`;

    return hour > 0 ? `+${offset}:00` : `${offset}:00`;
};
