import { createSlice } from '@reduxjs/toolkit';
import { Dialog } from 'chayns-api';

const initialState = {
    data: Array<Dialog>(),
};

const slice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        resetDialogs: () => initialState,
        setDialogs: (draft, action) => {
            draft.data = action.payload;
        },
    },
    extraReducers: () => {},
});

export const { resetDialogs, setDialogs } = slice.actions;

export const dialogsSlice = slice.reducer;
