import { BASE_URL } from '../../constants/urls';
import { Method, RequestOptions, RequestResult } from './types';
import arrayBufferToBase64 from '../arrayBufferToBase64';
import { logger } from '../logger';
import { containsHttpOrHttpsPrefix, getUtcOffsetAsString } from './utils';
import _ from 'lodash';

export const request = async <Data = null, Body = null>({
    body,
    method = Method.GET,
    url = BASE_URL,
    password,
    userName,
    fileName,
    accessToken,
}: RequestOptions<Body>): Promise<RequestResult<Data>> => {
    const headers: HeadersInit = {};

    if (userName && password) {
        const enc = new TextEncoder();
        let byteArray = enc.encode(`${userName}:${password}`);
        const token = arrayBufferToBase64(byteArray);

        headers.Authorization = `Basic ${token}`;
    }

    // Get token from local storage and append it as bearer token
    const token = localStorage.getItem('token');

    if (_.isString(token)) {
        headers.Authorization = `Bearer ${token}`;
    }

    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }

    headers.offset = getUtcOffsetAsString();

    const requestData: RequestInit = {
        headers,
        method,
    };

    if (method !== 'GET') {
        headers['Content-Type'] = 'application/json';

        if (body) {
            requestData.body = JSON.stringify(body);
        }
    }

    const result: RequestResult<Data> = {
        meta: {
            method,
            url: containsHttpOrHttpsPrefix(url) ? url : `${BASE_URL}${url}`,
            body: requestData.body,
        },
    };

    const requestStart: number = Date.now();

    try {
        const response: Response = await fetch(containsHttpOrHttpsPrefix(url) ? url : BASE_URL + url, requestData);

        result.requestDuration = Date.now() - requestStart;
        result.status = response.status;

        try {
            const dataString = await response.text();

            if (dataString && dataString.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                result.data = JSON.parse(dataString);
            }
        } catch (error) {
            if (error) {
                // @ts-expect-error: Type is correct here
                result.error = error;
            }
        }

        if (response.status === 429) {
            const retryAfterHeaderValue = response.headers.get('retry-after');

            // noinspection SuspiciousTypeOfGuard
            if (typeof retryAfterHeaderValue === 'string') {
                let parsedRetryAfterValue;

                try {
                    parsedRetryAfterValue = parseInt(retryAfterHeaderValue, 10);

                    if (typeof parsedRetryAfterValue === 'number' && !Number.isNaN(parsedRetryAfterValue)) {
                        result.retryAfter = parsedRetryAfterValue;
                    }
                } catch (error) {
                    if (error) {
                        // @ts-expect-error: Type is correct here
                        result.error = error;
                    }
                }
            }
        }
        if (response.status < 200 || response.status >= 300) {
            logger.error({
                fileName,
                message: `Request failed at ${fileName}`,
                data: result,
            });
        }
    } catch (error) {
        result.requestDuration = Date.now() - requestStart;

        if (error) {
            // @ts-expect-error: Type is correct here
            result.error = error;
        }
    }

    return result;
};
