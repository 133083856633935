import _ from 'lodash';
import { format } from 'date-fns';
import { StatusMessage } from '../types/statusMessages';

const groupStatusMessages = (messages: Array<StatusMessage>) =>
    _.groupBy(
        messages,
        (x) => `${format(new Date(x.startTime), 'yyyy-MM-dd')}-${format(new Date(x.endTime as string), 'yyyy-MM-dd')}`
    );

groupStatusMessages.displayName = 'mapStatusGroups';

export default groupStatusMessages;
