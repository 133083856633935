import { ApiFunctionResult, Method } from '../../utils/request/types';
import { request } from '../../utils/request/request';
import { Login, LoginCredentials } from '../../types/login';

const getLogin = async (credentials: LoginCredentials): Promise<ApiFunctionResult<Login, LoginCredentials>> => {
    const { password, userName } = credentials;
    return request<Login, LoginCredentials>({
        fileName: 'api/login_getLogin',
        method: Method.GET,
        url: `/login`,
        userName,
        password,
    });
};

export default getLogin;
