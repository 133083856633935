import React, { FC, useEffect, useMemo, useState } from 'react';
import { CardType, ContentCard } from '../ContentCard/ContentCard';
import textStrings from '../textString/textStrings';
import getTextStringValue from '../textString/getTextStringValue';
import _ from 'lodash';

interface NetworkStateProps {
    offlineMessage?: string;
    onReconnect?: () => void;
}

const NetworkState: FC<NetworkStateProps> = ({ offlineMessage, onReconnect }) => {
    const [online, setOnline] = useState<boolean>(window.navigator.onLine);
    const [wasOffline, setWasOffline] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('offline', () => setOnline(false));
        window.addEventListener('online', () => setOnline(true));
    }, []);

    useMemo(() => {
        if (!online) {
            setWasOffline(true);
            return;
        }
        if (!wasOffline) return;
        _.isFunction(onReconnect) && onReconnect();
        setWasOffline(false);
    }, [onReconnect, online, wasOffline]);

    return (
        <ContentCard
            show={!online}
            message={offlineMessage ?? getTextStringValue(textStrings.misc.network.offline)}
            type={CardType.WARNING}
        />
    );
};

NetworkState.displayName = 'NetworkState';

export default NetworkState;
