import React, { FC, useContext, useEffect, useState } from 'react';
import DataRow from '../DataRow/DataRow';
import { Button, Input } from 'chayns-components';
import useAppSelector from '../../hooks/useAppSelector';
import { selectIsLoggedIn, selectLoginError, selectLoginIsLoading } from '../../redux-modules/login/selectors';
import fetchLogin from '../../redux-modules/login/actions';
import useAppDispatch from '../../hooks/useAppDispatch';
import { DialogContext } from '../../contexts/dialog/context';
import Success from '../DialogSVG/Success';
import { CardType, ContentCard } from '../ContentCard/ContentCard';
import CenteredWaitCursor from '../CenteredWaitCursor/CenteredWaitCursor';
import getTextStringValue from '../textString/getTextStringValue';
import textStrings from '../textString/textStrings';
import TextStringWrapper from '../textString/TextStringWrapper';

const LoginDialog: FC = () => {
    const dispatch = useAppDispatch();

    const loginIsLoading = useAppSelector(selectLoginIsLoading);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const error = useAppSelector(selectLoginError);
    const { closeDialog } = useContext(DialogContext);

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        if (userName === '' || password === '') return;
        dispatch(fetchLogin({ userName, password }));
    };

    useEffect(() => {
        if (!isLoggedIn) return;
        setUserName('');
        setPassword('');

        setTimeout(() => {
            closeDialog();
        }, 2500);
    }, [isLoggedIn]);

    return (
        <>
            <ContentCard message={error?.displayMessage} type={CardType.WARNING} />
            {!isLoggedIn ? (
                <>
                    <DataRow>
                        <Input
                            disabled={loginIsLoading}
                            style={{ width: '100%' }}
                            dynamic
                            design={Input.BORDER_DESIGN}
                            placeholder={getTextStringValue(textStrings.misc.user)}
                            value={userName}
                            onEnter={handleLogin}
                            onChange={(input) => setUserName(input)}
                        />
                    </DataRow>
                    <DataRow>
                        <Input
                            disabled={loginIsLoading}
                            style={{ width: '100%' }}
                            dynamic
                            type={'password'}
                            design={Input.BORDER_DESIGN}
                            placeholder={getTextStringValue(textStrings.misc.password)}
                            value={password}
                            onEnter={handleLogin}
                            onChange={(input) => setPassword(input)}
                        />
                    </DataRow>
                    <DataRow>
                        {loginIsLoading ? (
                            <CenteredWaitCursor />
                        ) : (
                            <Button disabled={userName === '' || password === ''} onClick={handleLogin}>
                                <TextStringWrapper textString={textStrings.misc.login} />
                            </Button>
                        )}
                    </DataRow>
                </>
            ) : (
                <Success status={getTextStringValue(textStrings.dialog.messages.loginSuccess)} />
            )}
        </>
    );
};

LoginDialog.displayName = 'Login';

export default LoginDialog;
