import { createAsyncThunk } from '@reduxjs/toolkit';
import getRequestTimes from '../../api/request-times/get';
import { ErrorMessage } from '../../utils/request/types';
import { createCustomErrorMessage } from '../../utils/request/utils';
import { RequestTimes } from '../../types/requestTime';

const fetchRequestTimes = createAsyncThunk<Array<RequestTimes>, undefined, { rejectValue: ErrorMessage }>(
    'requestTimes/fetchRequestTimes',
    async (arg, { rejectWithValue }) => {
        const { data, status, errorMessage } = await getRequestTimes();

        if (status && status >= 200 && status < 300) {
            return data as Array<RequestTimes>;
        }

        return rejectWithValue(
            createCustomErrorMessage({
                message: errorMessage,
                fileName: 'redux-modules/request-times/actions_fetchRequestTimes',
            })
        );
    }
);

export default fetchRequestTimes;
