import _ from 'lodash';

export type Parameter = {
    [key: string]: string | number;
};

const parameterReplacer = (text: string, parameters?: [Parameter, ...Parameter[]]): string => {
    let result = text;

    parameters?.forEach((parameter: Parameter) => {
        const key = _.first(Object.keys(parameter));
        const value = _.first(Object.values(parameter));

        result = result.replace(`##${key as string}##`, value as string);
    });

    return result;
};

export default parameterReplacer;
