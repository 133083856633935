import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import textStringInit from './utils/textString/textStringInit';
import { Provider } from 'react-redux';
import store from './redux-modules/store';
import DialogContextProvider from './contexts/dialog/context';
import { AdminContextProvider } from './contexts/admin/context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

textStringInit().then();

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <DialogContextProvider>
                <AdminContextProvider>
                    <App />
                </AdminContextProvider>
            </DialogContextProvider>
        </Provider>
    </React.StrictMode>
);
