export interface RequestOptions<Body> {
    accessToken?: string;
    auth?: boolean;
    basicAuth?: boolean;
    body?: Body;
    method: Method;
    showWaitCursor?: boolean;
    url?: string;
    waitCursorText?: string;
    fileName: string;
    userName?: string;
    password?: string;
}

export enum Method {
    GET = 'GET',
    HEAD = 'HEAD',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    CONNECT = 'CONNECT',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE',
    PATCH = 'PATCH',
}

interface Meta {
    body?: BodyInit | null;
    method: string;
    url: string;

    [key: string]: unknown;
}

export interface RequestResult<Data = unknown> {
    data?: Data;
    error?: Error | undefined;
    meta: Meta;
    requestDuration?: number;
    retryAfter?: number;
    status?: number;

    [key: string]: unknown;
}

export interface ApiFunctionResult<T = null, E = null> {
    data?: T;
    error?: Error;
    errorData?: E;
    errorMessage?: string;
    retryAfter?: number;
    status?: number;
}

export type ErrorMessage = {
    message: string;
    errorCode: string;
    displayMessage: string;
};
