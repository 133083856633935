import { DomainResponse, ProxyStatus } from '../types/domain';
import { ProxyPatch } from '../types/proxy';

export const updateDomains = (state: DomainResponse, update: ProxyPatch) =>
    state.domains.map((domain) => {
        return {
            ...domain,
            subdomains: domain.subdomains.map((subDomain) => {
                const foundProxy = update.find((proxy) => proxy.subdomain === subDomain.domain);
                if (foundProxy)
                    return {
                        ...subDomain,
                        proxyStatus: foundProxy.proxied ? ProxyStatus.ENABLED : ProxyStatus.DISABLED,
                    };

                return subDomain;
            }),
        };
    });
