import { createAsyncThunk } from '@reduxjs/toolkit';
import getProjects from '../../api/projects/get';
import { ErrorMessage } from '../../utils/request/types';
import { createCustomErrorMessage } from '../../utils/request/utils';
import { Project } from '../../types/project';

const fetchProjects = createAsyncThunk<Array<Project>, undefined, { rejectValue: ErrorMessage }>(
    'projects/fetchProjects',
    async (arg, { rejectWithValue }) => {
        const { data, status, errorMessage } = await getProjects();

        if (status && status >= 200 && status < 300) {
            return data as any;
        }

        return rejectWithValue(
            createCustomErrorMessage({
                message: errorMessage,
                fileName: 'redux-modules/projects/actions_fetchProjects',
            })
        );
    }
);

export default fetchProjects;
