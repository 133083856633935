import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { AdminContextProps } from './types';
import { AdminContextProviderProps } from './interfaces';
import useAppDispatch from '../../hooks/useAppDispatch';
import { resetLogin, setLogin } from '../../redux-modules/login/slice';
import useAppSelector from '../../hooks/useAppSelector';
import {
    selectIsLoggedIn,
    selectLoginExpiration,
    selectLoginRoles,
    selectLoginToken,
} from '../../redux-modules/login/selectors';
import { DialogContext } from '../dialog/context';
import LoginDialog from '../../utils/CustomDialogs/LoginDialog';
import _ from 'lodash';
import { isAfter } from 'date-fns';
import getTextStringValue from '../../utils/textString/getTextStringValue';
import textStrings from '../../utils/textString/textStrings';
import { addLoginToken, getLoginToken, removeLoginToken } from '../../utils/token';

export const LoginContext = createContext({} as AdminContextProps);

export const AdminContextProvider: FC<AdminContextProviderProps> = ({ children }) => {
    const dispatch = useAppDispatch();

    const roles = useAppSelector(selectLoginRoles);
    const token = useAppSelector(selectLoginToken);
    const expires = useAppSelector(selectLoginExpiration);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    const { openDialog } = useContext(DialogContext);

    const [adminMode, setAdminMode] = useState<boolean>(false);

    const enableAdminMode = () => isLoggedIn && setAdminMode(true);

    const disableAdminMode = () => setAdminMode(false);

    const login = () => {
        if (isLoggedIn || !_.isEmpty(getLoginToken())) return;
        openDialog(<LoginDialog />);
    };

    const logout = (message?: string) => {
        void dispatch(resetLogin(message));
    };

    useEffect(() => {
        // Check if token is defined and add it to local storage
        if (!token) return;
        addLoginToken(token);
        setAdminMode(true);

        // Logout before token expires
        const interval = setInterval(() => {
            if (isAfter(new Date(), new Date(expires))) {
                removeLoginToken();
                logout(getTextStringValue(textStrings.dialog.messages.sessionExpired));
                clearInterval(interval);
            }
        }, 1000);

        // Only supposed to run if the token changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        void dispatch(setLogin());
        // this should only be run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LoginContext.Provider
            value={{
                login,
                logout,
                isLoggedIn: isLoggedIn,
                isAdminModeOn: adminMode,
                disableAdminMode,
                enableAdminMode,
                roles,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};
