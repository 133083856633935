import { ErrorMessage } from '../../utils/request/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import getDomains from '../../api/cloud-flare/domains/get';
import { createCustomErrorMessage } from '../../utils/request/utils';
import patchProxyGroup from '../../api/cloud-flare/proxy/group/patch';
import patchProxy from '../../api/cloud-flare/proxy/patch';
import { selectDomainsMeta } from './selectors';
import { RootState } from '../../types/store';
import { updateDomains } from '../../utils/cloudFlare';
import { DomainResponse } from '../../types/domain';
import { ProxyPatch } from '../../types/proxy';
import { GroupProxyPatch } from '../../types/group';
import { StatusMessage } from '../../types/statusMessages';

export const fetchDomains = createAsyncThunk<DomainResponse, undefined, { rejectValue: ErrorMessage }>(
    'cloudFlare/fetchDomains',
    async (arg, { rejectWithValue }) => {
        const { data, status, errorMessage } = await getDomains();

        if (status && status >= 200 && status < 300) {
            return data as DomainResponse;
        }

        return rejectWithValue(
            createCustomErrorMessage({
                message: (data as unknown as { errorMessage: string })?.errorMessage ?? errorMessage,
                errorCode: 'request_failed',
                fileName: 'redux-modules/cloud-flare/actions_updateProxy',
            })
        );
    }
);

export const updateProxyGroup = createAsyncThunk<
    DomainResponse & {
        message: Omit<StatusMessage, 'endTime' | 'project'>;
    },
    GroupProxyPatch,
    {
        rejectValue: ErrorMessage;
    }
>('cloudFlare/updateProxyGroup', async (arg, { getState, rejectWithValue }) => {
    const { data, status, errorMessage } = await patchProxyGroup(arg);

    if (status && status >= 200 && status < 300) {
        const state = selectDomainsMeta(getState() as RootState).data;
        const domains = updateDomains(state, data?.updatedDomains ?? []);

        return {
            groups: state.groups,
            domains,
            message: data?.message,
        } as DomainResponse & {
            message: Omit<StatusMessage, 'endTime' | 'project'>;
        };
    }

    return rejectWithValue(
        createCustomErrorMessage({
            message: errorMessage,
            fileName: 'redux-modules/cloud-flare/actions_updateProxyGroup',
        })
    );
});

export const updateProxy = createAsyncThunk<
    DomainResponse,
    ProxyPatch,
    {
        rejectValue: ErrorMessage;
    }
>('cloudFlare/updateProxy', async (arg, { getState, rejectWithValue }) => {
    const { status, errorMessage } = await patchProxy(arg);

    if (status && status >= 200 && status < 300) {
        const state = selectDomainsMeta(getState() as RootState).data;

        const domains = updateDomains(state, arg);

        return {
            ...state,
            domains,
        } as DomainResponse;
    }

    return rejectWithValue(
        createCustomErrorMessage({
            message: errorMessage,
            fileName: 'redux-modules/cloud-flare/actions_updateProxy',
        })
    );
});
