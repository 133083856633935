import { ApiFunctionResult, Method } from '../../utils/request/types';
import { request } from '../../utils/request/request';
import { StatusMessagePost, StatusMessagePostResult } from '../../types/statusMessages';

export const postStatusMessage = async (
    body: StatusMessagePost
): Promise<ApiFunctionResult<StatusMessagePostResult, StatusMessagePost>> =>
    request<StatusMessagePostResult, StatusMessagePost>({
        method: Method.POST,
        url: `/statusMessage`,
        body,
        fileName: 'api/status-message/post_postStatusMessage',
    });
