import React, { FC } from 'react';

interface SuccessProps {
    status?: string;
    subtext?: string;
}

const Success: FC<SuccessProps> = ({ status, subtext }) => {
    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <style>
                {'@keyframes checkmark { 0% { stroke-dashoffset: 50px } 100% { stroke-dashoffset: 0 } }'}
                {'@keyframes checkmark-circle { 0% { stroke-dashoffset: 240px } 100% { stroke-dashoffset: 480px } }'}
            </style>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72px"
                height="72px"
                style={{
                    transform: 'scale(1.4)',
                    padding: '20px 0',
                }}
            >
                <g fill="none" stroke="#349044" strokeWidth="3">
                    <circle
                        cx="36"
                        cy="36"
                        r="34"
                        style={{
                            strokeDasharray: '240px, 240px',
                            strokeDashoffset: 480,
                            animation: 'checkmark-circle 0.6s ease-in-out backwards',
                        }}
                    />
                    <path
                        d="M17.417,37.778l9.93,9.909l25.444-25.393"
                        style={{
                            strokeDasharray: '50px, 50px',
                            strokeDashoffset: 0,
                            animation: 'checkmark 0.25s ease-in-out 0.7s backwards',
                        }}
                    />
                </g>
            </svg>
            <div className="status">{status}</div>
            <footer>{subtext}</footer>
        </div>
    );
};
export default Success;
