import { ApiFunctionResult, Method } from '../../../../utils/request/types';
import { request } from '../../../../utils/request/request';
import { GroupProxyPatch, GroupProxyPatchResponse } from '../../../../types/group';

const patchProxyGroup = async (
    body: GroupProxyPatch
): Promise<ApiFunctionResult<GroupProxyPatchResponse, GroupProxyPatch>> =>
    request<GroupProxyPatchResponse, GroupProxyPatch>({
        method: Method.PATCH,
        url: `/cloudflare/proxy/group`,
        body,
        fileName: 'api/cloud-flare/proxy/group_patchProxyGroup',
    });

export default patchProxyGroup;
