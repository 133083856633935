import React, { FC } from 'react';
import ApiV5Tapp from './api-v5-tapp';
import useAppSelector from '../../hooks/useAppSelector';
import { selectDialogs } from '../../redux-modules/dialog/selectors';
import { dialogsGlobal } from '../../utils/DialogHelper/DialogHelper';
import { IS_QA, IS_STAGING } from '../../constants/environment';

const Dialog: FC = () => {
    const dialogs = useAppSelector(selectDialogs);

    // only used for rerender
    // console.debug('dialogs', dialogs);

    return (
        <div className="dialog-v2-wrapper">
            <ApiV5Tapp
                // @ts-ignore
                // url="dialog_v2:AppWrapper@https://w-mgertdenken-l.tobit.ag:8080/remoteEntry.js"
                // url="dialog_v2:AppWrapper@https://w-mg-surface.tobit.ag:8080/remoteEntry.js"
                // url="dialog_v2:AppWrapper@https://w-hn-laptop.tobit.ag:8080/remoteEntry.js"
                // url="dialog_v2:AppWrapper@https://w-lharat-l.tobit.ag:8080/remoteEntry.js"
                url={`dialog_v2:AppWrapper@${window.origin}${
                    IS_QA ? '/chaynsStatusPage' : IS_STAGING ? '/chayns-systems-staging' : ''
                }/build/remoteEntry.js`}
                moduleFederationTapp
                // @ts-ignore
                customData={{ dialogs: dialogsGlobal.list }}
            />
        </div>
    );
};

Dialog.displayName = 'Dialog';

export default Dialog;
