import { ITypedState, RootState } from '../../types/store';
import { ErrorMessage } from '../../utils/request/types';
import _ from 'lodash';
import { MappedProjectRequestTimes } from '../../types/requestTime';

export const selectRequestTimesMeta = (state: RootState): ITypedState<Array<MappedProjectRequestTimes>> =>
    state.requestTimes;
export const selectAllRequestTimes = (state: RootState): Array<MappedProjectRequestTimes> =>
    selectRequestTimesMeta(state).data;
export const selectRequestTimesError = (state: RootState): ErrorMessage => selectRequestTimesMeta(state)?.error;
export const selectRequestTimesIsLoading = (state: RootState): boolean => selectRequestTimesMeta(state).isLoading;
export const selectRequestTimesByProjectId = (state: RootState, id: number): Array<MappedProjectRequestTimes> => {
    if (_.isEmpty(selectAllRequestTimes(state))) return Array<MappedProjectRequestTimes>();
    return selectAllRequestTimes(state)?.filter((requestTime) => requestTime.projectId === id);
};
