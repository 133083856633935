import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorMessage } from '../../utils/request/types';
import getLogin from '../../api/login/get';
import { createCustomErrorMessage } from '../../utils/request/utils';
import getTextStringValue from '../../utils/textString/getTextStringValue';
import textStrings from '../../utils/textString/textStrings';
import { Login, LoginCredentials } from '../../types/login';

const fetchLogin = createAsyncThunk<Login, LoginCredentials, { rejectValue: ErrorMessage }>(
    'login/fetchLogin',
    async (arg, { rejectWithValue }) => {
        const { data, status, error } = await getLogin(arg);

        if (status && status >= 200 && status < 300) {
            return {
                roles: [],
                ...data,
            } as Login;
        }

        if (status === 401) {
            return rejectWithValue({
                errorCode: 'login/unauthorized',
                displayMessage:
                    getTextStringValue(textStrings.dialog.messages.loginFailure) ?? 'Login credentials incorrect',
                message: 'Not authorized',
            } as ErrorMessage);
        }

        if (error)
            return rejectWithValue(
                createCustomErrorMessage({
                    message: error?.message,
                    fileName: 'redux-modules/login/actions_fetchLogin',
                })
            );

        return rejectWithValue(data as unknown as ErrorMessage);
    }
);

export default fetchLogin;
