import { TextString } from 'chayns-components';
import React, { FC } from 'react';
import projectConfig from '../../../package.json';
import { findObjectPaths } from 'find-object-paths';
import textStrings from './textStrings';

interface TextStringWithFallbackProps {
    textString: string;
    className?: string;
    children?: React.ReactElement;
}

const TextStringWrapper: FC<TextStringWithFallbackProps> = ({ textString, className, children }) => {
    const { textLibName } = projectConfig;

    const suffix = findObjectPaths(textStrings, { value: textString })?.toString().replaceAll('.', '_');

    return (
        <TextString className={className} stringName={`txt_${textLibName}_${suffix}`} fallback={textString}>
            {children}
        </TextString>
    );
};

export default TextStringWrapper;
