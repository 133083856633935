import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { RootState } from '../types/store';

type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default useAppDispatch;
