import { IS_PRODUCTION, IS_QA, IS_STAGING } from './environment';

const URL_DEV = 'http://staging.chayns-status.ey.r.appspot.com';
// noinspection HttpUrlsUsage Needs to be over http beacuse of missing certificate on the server
const URL_QA = 'http://staging.chayns-status.ey.r.appspot.com';
// noinspection HttpUrlsUsage Needs to be over http beacuse of missing certificate on the server
const URL_STAGING = 'http://staging.chayns-status.ey.r.appspot.com';
const URL_PRODUCTION = 'https://chayns-status.ey.r.appspot.com';

const BASE_ROUTE = () => {
    switch (true) {
        case IS_PRODUCTION:
            return URL_PRODUCTION;
        case IS_STAGING:
            return URL_STAGING;
        case IS_QA:
            return URL_QA;
        default:
            return URL_DEV;
    }
};

export const BASE_URL = BASE_ROUTE();

export const FORUM = {
    desktop: 'https://chayns.net/forum',
    mobile: 'https://chayns.cc/w42u14',
};

export const TUTORIALS = {
    desktop: 'https://chayns.net/tutorials',
    mobile: 'https://chayns.cc/ttd56g',
};

export const WORKSHOPS = {
    desktop: 'https://labs.tobit.com/termine',
    mobile: 'https://chayns.cc/njszmq',
};

export const SUPPORT_HOTLINE = '02561 913 123';
