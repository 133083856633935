import { createSlice } from '@reduxjs/toolkit';
import { ITypedState } from '../../types/store';
import fetchLogin from './actions';
import { ErrorMessage } from '../../utils/request/types';
import { createCustomErrorMessage } from '../../utils/request/utils';
import { Login } from '../../types/login';
import { addLoginToken, getLoginToken, removeLoginToken } from '../../utils/token';
import _ from 'lodash';
import parseJwt from '../../utils/jwt';
import { Token } from '../../types/token';
import { isAfter, millisecondsInSecond } from 'date-fns';

const initialState: ITypedState<Login> = {
    data: {} as Login,
    isLoading: false,
    error: {} as ErrorMessage
};

const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        resetLogin: (draft, action) => {
            draft.isLoading = false;
            draft.data = {} as Login;
            draft.error = createCustomErrorMessage(action.payload);

            removeLoginToken();
        },
        setLogin: (draft) => {
            draft.isLoading = false;
            draft.error = {} as ErrorMessage;

            const localStorageToken = getLoginToken();

            if (_.isNull(localStorageToken)) return;

            const parsedToken = parseJwt<Token>(localStorageToken as string);

            if (isAfter(new Date(parsedToken.exp), new Date())) {
                removeLoginToken();
                return;
            }

            draft.data = {
                roles: parsedToken.roles,
                token: localStorageToken,
                expires: new Date(parsedToken.exp * millisecondsInSecond).toISOString()
            };
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchLogin.pending, (draft) => {
                draft.isLoading = true;
            })
            .addCase(fetchLogin.fulfilled, (draft, action) => {
                draft.isLoading = false;
                draft.data = action.payload;
                draft.error = {} as ErrorMessage;

                addLoginToken(action.payload.token);
            })
            .addCase(fetchLogin.rejected, (draft, action) => {
                draft.isLoading = false;
                draft.error = action.payload as ErrorMessage;
            })
});

export const { resetLogin, setLogin } = slice.actions;

export const loginSlice = slice.reducer;
