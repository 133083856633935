import React, { FC } from 'react';
import './contend-card.scss';
import clsx from 'clsx';

export enum CardType {
    SUCCESS = `success`,
    WARNING = 'warning',
    DANGER = 'danger',
}

interface ContentCardProps {
    type?: CardType;
    message: string;
    show?: boolean;
}

export const ContentCard: FC<ContentCardProps> = ({ type = CardType.SUCCESS, message, show = true }) => (
    <>
        {message && show ? (
            <div
                className={clsx('content__card', {
                    'content__card--success': type === CardType.SUCCESS,
                    'content__card--warning': type === CardType.WARNING,
                    'content__card--danger': type === CardType.DANGER,
                })}
            >
                {message}
            </div>
        ) : null}
    </>
);
